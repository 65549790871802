@import 'src/styles/base';

.wrapper {
  $padding: calc(min(5%, 16px));

  @include padding-x($padding);

  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  color: $midnight100;
  background: $white100;

  .col > div {
    width: $col-width;
  }

  .col .wip {
    @include with-light-drop-shadow;

    padding: 16px $padding;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    > div {
      flex: 1;
    }

    .wip-title {
      font-size: 16px;
    }

    .wip-body {
      > div {
        width: 18ch;
      }
    }
  }
}

.wrapper-top {
  text-align: center;
  padding-bottom: 12px;

  .icon.heart {
    width: 55px;
    height: 55px;
  }

  .practice-name {
    @include text($font-size: 16px, $line-height: 1);

    margin-top: 24px;
    font-weight: 600;
  }

  .loan-id {
    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      font-size: 13px;
    }

    margin: 16px 0 10px;
    font-size: 12px;
    color: $grey1300;
  }

  .payoff-balance {
    @include emphasized-text;

    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      font-size: 32px;
    }

    font-size: 30px;
    color: $midnight100;
    padding-bottom: 16px;
  }
}

.custom.loading {
  margin-top: $gutter;
}

.next-payment {
  @include with-light-drop-shadow;

  $font-size-value: 13px;

  padding-top: 12px;
  font-weight: 600;

  .list-item {
    gap: calc(clamp(2px, 5%, 16px));
    align-items: flex-start;
    justify-content: center;
    padding-top: unset;

    .next-payment-practice-name {
      @include text($font-size: 16px, $line-height: 1);

      margin-bottom: $spacing-bottom-text;

      /* creates ellipses on 2nd line */
      @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: -o-ellipsis-lastline;
        text-overflow: ellipsis;
        display: block;

        /* autoprefixer: off */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 32px;
      }
    }

    .next-payment-id {
      @include subtle-text;
    }

    .next-payment-due {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: $spacing-bottom-text;

      .late-fee {
        color: $red100;
        font-size: $font-size-value;
      }
    }

    .next-payment-heading,
    .next-payment-autopay-setting {
      @include subtle-text($font-size: 11px);
    }

    .next-payment-heading {
      padding-bottom: $spacing-bottom-text-sm;
    }

    .next-payment-value {
      font-size: $font-size-value;

      &:not(:last-child) {
        padding-bottom: $spacing-bottom-text;
      }
    }

    .next-payment-autopay {
      display: flex;
      justify-content: space-between;
    }

    /* stylelint-disable */
    /* fixing no-descending-specificity conflicts with convention of wrapper being at top */
    > div {
      @include respond-to-smaller-than-grid-4 {
        width: 47.5%;
      }

      width: 150px;
      margin: 0 auto;
    }
    /* stylelint-enable */
  }

  .list-item.next-payment-payment {
    align-items: baseline;

    button {
      width: 95%;

      @include respond-to-smaller-than-grid-4 {
        @include padding-x(8px);

        font-size: 11px;
      }
    }
  }
}
