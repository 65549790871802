@import 'src/styles/base';

$toast-padding: 16px;

:global {
  .Toastify__toast-container.Toastify__toast-container--top-center {
    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      left: 50% !important;
      transform: translateX(-50%) !important;
    }

    width: auto;
    left: $toast-padding;
    right: $toast-padding;
    max-width: 450px;
  }

  .Toastify__toast.Toastify__toast--default.toast {
    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      font-size: 16px;
    }

    padding: 20px;
    border-radius: 4px;
    font-size: 14px;
    margin: calc(#{$toast-padding} / 2) 0 0;

    &:first-child {
      margin-top: $toast-padding;
    }
  }
}
