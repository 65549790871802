@import 'src/styles/base';

.wrapper {
  @include margin-x;

  color: $midnight100;
  background: $white100;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  width: $col-width-sm;

  .make-payment {
    @include with-light-drop-shadow;

    padding: 24px 16px;
    width: 100%;

    @include respond-to-grid-4 {
      @include padding-x(0);
    }
  }

  .payment-method {
    @extend .make-payment;

    padding: 0;

    div {
      border-radius: 15px;
    }
  }

  .card-title {
    @include card-title;
  }

  .agreement-link {
    text-align: left;
    padding-top: 16px;
  }

  .payment-btn {
    padding-top: 32px;

    button {
      width: 100%;
    }
  }

  .payment-btn,
  .agreement-link {
    width: 100%;
  }

  .error-message {
    color: $red100;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1em;
  }
}
