@font-face {
  font-family: Muli;
  font-style: normal;
  font-weight: 200;
  src: url('../../assets/fonts/Muli/Muli-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: Muli;
  font-style: italic;
  font-weight: 200;
  src: url('../../assets/fonts/Muli/Muli-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: Muli;
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/Muli/Muli-Light.ttf') format('truetype');
}

@font-face {
  font-family: Muli;
  font-style: italic;
  font-weight: 300;
  src: url('../../assets/fonts/Muli/Muli-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: Muli;
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/Muli/Muli-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Muli;
  font-style: italic;
  font-weight: 400;
  src: url('../../assets/fonts/Muli/Muli-RegularItalic.ttf') format('truetype');
}

@font-face {
  font-family: Muli;
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/Muli/Muli-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Muli;
  font-style: italic;
  font-weight: 700;
  src: url('../../assets/fonts/Muli/Muli-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: Muli;
  font-style: normal;
  font-weight: 900;
  src: url('../../assets/fonts/Muli/Muli-Black.ttf') format('truetype');
}

@font-face {
  font-family: Muli;
  font-style: italic;
  font-weight: 900;
  src: url('../../assets/fonts/Muli/Muli-BlackItalic.ttf') format('truetype');
}
