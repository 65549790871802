@import 'src/styles/base';

$small-size-profile-photo: 48px;
$large-size-profile-photo: 54px;
$small-size-icon: 24px;
$large-size-icon: 32px;

.wrapper {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    padding: 0;
  }

  height: 100vh;
  padding: 0 32px;
  position: relative;
}

.user-profile {
  margin: 24px 0 36px;
  text-align: center;

  .icon-photo {
    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      height: $large-size-profile-photo;
      width: $large-size-profile-photo;
    }

    height: $small-size-profile-photo;
    width: $small-size-profile-photo;
  }

  .fullname {
    @include emphasized-text;

    font-size: 18px;
    margin-top: 12px;
    text-transform: capitalize;
  }
}

.contact-info-wrapper {
  .contact-info {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;

    .info {
      @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
        font-size: 16px;
        line-height: $large-size-icon;
      }

      color: $text-default;
      font-size: 14px;
      line-height: $small-size-icon;
    }

    .icon {
      @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
        font-size: 18px;
        height: $large-size-icon;
        width: $large-size-icon;
      }

      color: $purple100;
      height: $small-size-icon;
      margin-right: 8px;
      width: $small-size-icon;
    }

    & + .contact-info {
      margin-top: 16px;
    }
  }
}

.content-action-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.content-wrapper {
  width: 100%;
}

.fixed-bottom-button {
  @include simple-button;

  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    font-size: 16px;
  }

  color: $orange500;
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
  text-transform: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  z-index: 1000;
}
