@import 'src/styles/base';

$small-size-icon: 24px;
$large-size-icon: 32px;

.wrapper {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    padding: 0;
  }

  padding: 32px;
}

.title {
  @include emphasized-text;

  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    font-size: 36px;
  }

  font-size: 32px;
}

.sub-title {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    font-size: 18px;
  }

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: 8px 0 16px;
}

.contact-info-wrapper {
  .contact-info {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;

    .info {
      @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
        font-size: 16px;
        line-height: $large-size-icon;
      }

      color: $text-default;
      font-size: 14px;
      line-height: $small-size-icon;
    }

    .icon {
      @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
        font-size: 18px;
        height: $large-size-icon;
        width: $large-size-icon;
      }

      color: $purple100;
      height: $small-size-icon;
      margin-right: 8px;
      width: $small-size-icon;
    }

    & + .contact-info {
      margin-top: 16px;
    }
  }
}
