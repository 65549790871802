@import 'src/styles/base';

.wrapper {
  @include respond-to-grid-xlarger {
    margin-left: auto;
    margin-right: auto;
    max-width: $screen-xlg;
  }

  background: transparent;
  bottom: 20px;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1;

  svg {
    path {
      fill: $primary;
    }
  }

  .fab {
    width: 100%;

    * {
      font-family: $font-baloo-bhai !important;
    }

    &:disabled {
      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  @include respond-to-grid-4 {
    svg {
      height: 24px;
      width: 24px;
    }
  }

  @include respond-to-grid-8 {
    svg {
      height: 24px;
      width: 24px;
    }
  }

  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    svg {
      height: 32px;
      width: 32px;
    }
  }
}
