@import 'src/styles/base';

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &.info {
    color: $blue200;
  }

  &.success {
    color: $primary;
  }

  &.error {
    color: $orange500;
  }

  .icon-wrapper {
    margin-right: 12px;

    svg {
      width: 40px;
      height: 40px;
    }
  }
}
