@import 'src/styles/base';

.wrapper {
  padding-bottom: $spacing-bottom-list;
}

.list.scheduled-payments {
  @include custom-list;
}

.list-item.scheduled-payment {
  @include custom-list-item;
}

.amounts-wrapper {
  .amount {
    align-items: center;
    border: solid 1px $green100;
    border-radius: 4px;
    display: inline-flex;
    font-size: 14px;
    font-weight: bold;
    height: 22px;
    justify-content: center;
    padding: 0 5px;
    background: $green100;
    color: $white;
    min-width: 66px;
  }

  &.due {
    .amount {
      border-color: $yellow500;
      background: $yellow500;
    }
  }

  &.late-due {
    .amount {
      border-color: $orange500;
      background: $orange500;
    }
  }
}

.date {
  font-size: 16px;
  font-weight: 700;
}

.no-data-wrapper {
  @include respond-to-grid-4 {
    margin: 0 32px;
  }

  color: $black200;
  font-size: 14px;
  text-align: center;
}
