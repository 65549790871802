@import 'src/styles/base';

.wrapper {
  &.main-header {
    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      height: $header-height-large;
    }

    background: $white;
    align-items: center;
    display: flex;
    flex-direction: row;
    height: $header-height-small;
  }
}

.icon.menu {
  path {
    fill: $purple100;
  }

  cursor: pointer;
  margin-right: 10px;
  height: 30px;
  width: 30px;
}

.full-name {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    font-size: 18px;
  }

  color: $black;
  flex: 1;
  font-size: 16px;
  font-weight: 700;
  line-height: 50px;
  text-transform: capitalize;
}

.btn {
  &.btn-toggle-drawer {
    border-radius: 0;
    padding: 5px;
    margin-left: auto;
    margin-right: 480px;

    &:hover {
      background: transparent;
    }
  }
}
