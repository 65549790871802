@import 'src/styles/base';

.wrapper {
  $padding: calc(min(5%, 16px));

  @include padding-x($padding);

  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  color: $midnight100;
  background: $white;

  .col > div {
    width: $col-width;
  }
}

.wrapper-top {
  text-align: center;
  padding-bottom: 12px;
  padding-top: 25px;

  .icon.heart {
    width: 55px;
    height: 55px;
  }

  .title-text {
    text-align: left;
    font-size: 18px;
    color: $midnight100;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 700;
    margin-top: 10px;
  }

  .generic-text {
    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      font-size: 14px;
      text-align: left;
      color: $midnight100;
      letter-spacing: 0;
      line-height: 18px;
      font-weight: 400;
    }

    margin: 16px 0 10px;
  }

  .centered {
    text-align: center;
  }
}

.action-button {
  background: $midnight50;
  border-radius: 7px;
  border-width: 0;
  font-size: 14px;
  color: $grey1200;
  letter-spacing: 0;
  text-align: center;
  line-height: 40px;
  font-weight: 700;
  width: 100%;
  margin: 20px 0;
  cursor: pointer;
}

.generic-col {
  $font-size-value: 13px;

  text-align: left;
  background: #f9f8ff;
  border-radius: 9px;
  padding: 25px;
  margin: 35px 0;
}

.icon-box {
  text-align: left;

  .text {
    color: $midnight100;
  }

  .icon {
    width: 37px;
    height: 37px;

    svg path {
      fill: $midnight100;
    }
  }
}

.close-link-wrapper {
  padding: 20px 0;
}
