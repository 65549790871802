@import 'src/styles/base';

.wrapper {
  text-align: left;

  .wip-end-date {
    font-weight: 700;
    color: $midnight50;
  }
}
