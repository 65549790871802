@import 'src/styles/base';

.delinquency-notice {
  border-top: 2px solid #eee;
  margin-top: 24px;

  p {
    line-height: normal;
  }
}
