@import 'src/styles/base';

.wrapper {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    padding: 0 32px;
  }

  padding: 0 $gutter;

  & + .wrapper {
    margin-top: 40px;
  }
}

.list.payment-plans {
  margin: 0;
  padding: 0;
}

.list-item.payment-plan {
  display: block;
  padding: 0;

  & + .list-item {
    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      margin-top: 32px;
    }

    margin-top: 16px;
  }
}

.border-wrapper {
  @include has-border-top-radius;

  border: solid 0.5px $grey1000;
  border-bottom: 0;
  border-top: 0;
}

.title {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    font-size: 14px;
    margin-bottom: 16px;
    color: #001684;
  }

  margin-bottom: 8px;
  text-align: left;
  font-size: 12px;
}
