@import 'src/styles/base';

.wrapper {
  position: fixed;
  z-index: 10001;
  background: get-black-opacity(0.5);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;

  .animation {
    max-width: 100px;
    max-height: 100px;
    color: $white100; // for alt text
  }
}
