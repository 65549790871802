@import 'src/styles/base';

.iframe {
  border: 0;
  height: 350px;
  width: 100%;
  min-width: 350px;
  border-radius: 4px;
  margin-top: -20px;
  margin-bottom: -6px;
  margin-left: -2px;

  @include respond-to-smaller-than-grid-4 {
    margin-top: -30px;
    margin-bottom: -15px;
    margin-left: -13px;
    width: 350px;
  }
}

.payment-note {
  font-size: 12px;
  margin: 10px auto 0;
  max-width: 305px;
  text-align: center;
}

.custom.loading {
  margin-top: $gutter;
}
