@import 'src/styles/base';

.wrapper {
  padding-bottom: $spacing-bottom-list;
}

.container {
  padding: 20px 20px 0;
}

.header {
  padding-right: 10px;
  font-size: 1.5rem !important;
}

.loan-header {
  font-size: 1rem;
  padding: 0 20px;
  font-weight: 700 !important;
}

.expansion-details {
  padding: 10px 20px;
  border-top: 1px solid lightgrey !important;
}

.list-root {
  width: 100%;
  display: block !important;
}

.loan-documents {
  max-height: 60vh;
  overflow-y: scroll;
}

.no-data-wrapper {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    font-size: 16px;
  }

  @include respond-to-grid-4 {
    margin: 0 32px;
  }

  color: $black200;
  font-size: 14px;
  text-align: center;

  a {
    font-weight: bold;
    text-decoration: underline;
  }
}
