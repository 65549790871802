@import 'src/styles/base';

.wrapper {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    padding-left: 0;
    padding-right: 0;
  }

  padding: 0 32px 100px;
}

.dialog {
  &.error {
    min-height: 140px;
  }
}
