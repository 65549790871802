@import 'src/styles/base';

.wrapper {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    padding-left: 0;
    padding-right: 0;
  }

  padding: 0 32px;

  .content-wrapper {
    margin-top: 20px;
  }
}

.overview-holdername {
  padding: 0 0 20px;

  .label {
    font-size: 12px;
    line-height: 15px;
  }

  .value {
    @include emphasized-text;

    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      font-size: 20px;
    }

    font-size: 18px;
    margin-top: 8px;
  }
}

.block {
  border-top: 1px solid $blue100;
  padding: 20px 0 0;

  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    .label {
      color: $grey700;
      font-size: 12px;
    }

    .value {
      align-items: center;
      color: $black200;
      display: flex;
      flex-direction: row;
      font-size: 14px;

      svg {
        margin-right: 10px;
      }
    }

    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      margin-bottom: 16px;

      .label {
        font-size: 14px;
      }

      .value {
        font-size: 16px;
      }
    }
  }
}

.btn {
  &.delete-method {
    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      font-size: 14px;
    }

    @include simple-button;

    color: $purple100;
    float: right;
    font-size: 12px;
    font-weight: 700;
    text-align: right;
    text-decoration: underline;
  }
}
