@import 'src/styles/base';

.wrapper {
  .icon {
    @include margin-x(auto);

    width: 55px;
    height: 55px;
    margin-bottom: 16px;
  }
}
