@import 'src/styles/base';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 1121px;
  padding-top: 64px;
  position: relative;
  width: 794px;
}

.header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  padding: 0 32px;

  .left-block {
    color: $primary;
    font-size: 24px;
    line-height: 30px;

    span {
      display: block;
    }
  }

  .right-block {
    color: $grey800;
    text-align: right;

    span {
      display: block;
    }
  }
}

.footer {
  align-items: center;
  background: $primary;
  color: $white;
  display: flex;
  height: 100px;
  justify-content: space-between;
  line-height: 15px;
  padding: 0 64px;

  .left-block {
    * {
      display: block;
    }

    img {
      height: 35px;
      margin-top: 4px;
    }
  }

  .right-block {
    text-align: right;

    * {
      display: block;
    }
  }
}

.content {
  flex: 1;
  padding: 0 64px;
}

.tag-refund-wrapper {
  display: flex;
  justify-content: center;

  .tag-refund {
    background: $grey300;
    color: $white;
    line-height: 20px;
    padding: 0 6px;
    font-size: 10px;
    border-radius: 3px;
    margin-bottom: 15px;

    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      line-height: 24px;
      padding: 0 8px;
      font-size: 12px;
    }
  }
}

.full-name {
  text-transform: capitalize;
}
