@import 'src/styles/base';

.set-primary-payment-method-info {
  margin: 0;

  & + .set-primary-payment-method-info {
    margin-top: 10px;
  }

  span {
    font-weight: 700;
  }
}
