@import 'src/styles/base';

.selected-loan {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: $text-default;

  &.disabled {
    opacity: 0.38;
  }

  .spinner {
    margin-right: 5px;
  }
}

.menu-item.default-item {
  color: $black500;
  font-size: 14px;
  line-height: 18px;
  max-height: 48px;
  padding: 0 24px;

  &:hover {
    background: $grey400;
  }

  &.selected {
    background: transparent;
    font-weight: 700;
    position: relative;

    &::before {
      background: url('~assets/icons/icon-green-check.svg') no-repeat center;
      content: '';
      height: 9px;
      left: 8px;
      position: absolute;
      width: 12px;
    }
  }
}

.custom.loading {
  display: block !important;
  margin: 10px auto;
}
