@import 'src/styles/base';

.wrapper-payment-plan {
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 4px;
  box-shadow: $grey-box-shadow;
  cursor: pointer;
}

.wrapper-info {
  padding: 12px 16px 16px;
  position: relative;
}

.block {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    font-size: 12px;
    margin-top: 3px;
  }

  color: $primary;
  align-items: center;
  display: flex;
  font-size: 12px;
  justify-content: space-between;

  & + .block {
    margin-top: 3px;
  }

  &.practice {
    @include emphasized-text;

    max-width: 200px;
  }

  &.late-fees {
    color: $red100;
  }

  &.src-loan {
    opacity: 0.3;
  }

  &.src-loan-text {
    font-weight: bold;
  }

  .late-fees-value {
    display: flex;
    flex-direction: row;
    justify-content: center;
    line-height: 14px;

    .value {
      @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
        font-size: 14px;
      }

      font-family: $font-baloo-bhai !important;
      font-size: 12px;
      font-weight: 700;
      color: $red100;
      margin-top: 3px;
    }
  }

  .payoff-balance-value {
    display: flex;
    flex-direction: row;

    .value {
      @include emphasized-text;
    }

    &.paidoff {
      padding: 3px 5px 0;
      line-height: 18px;
      border-radius: 4px;
      background: $green100;
      font-weight: 700;

      .value {
        color: $white;
      }
    }
  }
}

.wrapper-toggle-autopay {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    height: 48px;
  }

  position: relative;
  height: 40px;
  padding: 0 16px;
  border-top: 1px solid $bg-main;
  margin: 0;

  .wrapper-primary-payment-method-autopay {
    .label {
      margin-right: 8px;
      font-weight: 900;
      font-size: 14px;
    }

    display: flex;
    flex-direction: row;
  }
}

.custom {
  &.loading {
    margin-left: 5px;

    svg {
      color: $purple100;
    }
  }
}

.has-scheduled-payments {
  @include has-border-top-radius;

  border: solid 0.5px $grey1000;
  border-bottom: 0;
  border-top: 0;
}

.payoff-balance {
  margin-top: 5px;
  flex: 1;
  text-align: right;
}
