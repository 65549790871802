$screen-sm: 600px;
$screen-md: 960px;
$screen-lg: 1024px;
$screen-xlg: 1280px;
$max-grid-4: $screen-sm - 1;
$min-grid-8: $screen-sm;
$max-grid-8: $screen-md - 1;
$min-grid-12: $screen-md;
$max-grid-12: $screen-lg - 1;
$min-grid-larger: $screen-lg;
$max-grid-xlarger: $screen-xlg - 1;

@mixin respond-to-smaller-than-grid-4 {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin respond-to-grid-4 {
  @media (max-width: $max-grid-4) {
    @content;
  }
}

@mixin respond-to-grid-8 {
  @media (min-width: $min-grid-8) and (max-width: $max-grid-8) {
    @content;
  }
}

@mixin respond-to-grid-12 {
  @media (min-width: $min-grid-12) and (max-width: $max-grid-12) {
    @content;
  }
}

@mixin respond-to-grid-larger {
  @media (min-width: $min-grid-larger) and (max-width: $max-grid-xlarger) {
    @content;
  }
}

@mixin respond-to-grid-xlarger {
  @media (min-width: $screen-xlg) {
    @content;
  }
}

@mixin respond-to-grid($is-grid-12, $is-grid-lg, $is-grid-xlg) {
  @if $is-grid-12 {
    @include respond-to-grid-12 {
      @content;
    }
  }

  @if $is-grid-lg {
    @include respond-to-grid-larger {
      @content;
    }
  }

  @if $is-grid-xlg {
    @include respond-to-grid-xlarger {
      @content;
    }
  }
}
