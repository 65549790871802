@import 'src/styles/base';

.wrapper {
  @include with-light-drop-shadow;

  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    padding-left: 32px;
    padding-right: 32px;
  }

  padding: $spacing $spacing;

  &.no-data {
    font-size: 12px;
    text-align: center;

    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  &.list-payments {
    padding-bottom: $spacing-bottom-list;
  }
}

.date {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    font-size: 14px;
  }

  font-size: 12px;
  font-weight: bold;
}

.list.payments {
  @include custom-list;

  border: $default-border-style;
  border-radius: 8px;
}

.list-item.payment {
  @include custom-list-item;
  @include custom-list-item-has-no-left-and-right-border-on-large-screen;

  cursor: pointer;
  border-left: 0;
  border-right: 0;

  &:first-child {
    border-top: 0;
  }

  &:last-child {
    border-bottom: 0;
  }

  &.has-refund {
    .date,
    .amount-wrapper *:not(.tag-refund) {
      opacity: 0.4;
    }
  }
}

.amount-wrapper {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
}

.amount {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    font-size: 16px;
  }

  font-size: 14px;
  margin-left: 10px;
}

.tag-refund {
  background: $grey300;
  color: $white;
  line-height: 20px;
  padding: 0 6px;
  font-size: 10px;
  border-radius: 3px;
  margin-right: 10px;

  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    line-height: 24px;
    padding: 0 8px;
    font-size: 12px;
  }

  & + .amount {
    margin-left: 0;
  }
}

.custom.loading {
  margin-bottom: $gutter;
}
