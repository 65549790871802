@import 'src/styles/base';

.wrapper {
  margin: 12px 0 0;

  p {
    margin: 0;
    padding: 0;
  }
}
