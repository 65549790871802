@import 'src/styles/base';

.wrapper {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    padding: 0;
  }

  padding: 0 32px;
  background: $primary;
  height: 100vh;
}

.wrapper-logo {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    padding-top: $header-height-large;
  }

  @include respond-to-smaller-than-grid-4 {
    padding: $gutter 0;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $header-height-small 0 30px;

  .icon.heart {
    width: 55px;
    height: 55px;
    margin-bottom: 16px;

    @include respond-to-smaller-than-grid-4 {
      width: 40px;
      height: 40px;
    }
  }

  .logo {
    @include respond-to-grid-4 {
      max-width: 190px;
    }

    @include respond-to-smaller-than-grid-4 {
      max-width: 150px;
    }

    height: auto;
    max-width: 212px;
    width: 100%;
  }
}

.disclaimer {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    font-size: 14px;
    line-height: 16px;
  }

  color: $white;
  opacity: 0.8;
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;

  &.text-above-fab {
    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      font-size: 10px;
      line-height: 12px;
      padding-right: 0;
      padding-left: 0;
    }

    font-size: 9px;
    line-height: 11px;
    margin: 0;
    padding: 0 30px 16px;
    text-align: center;
  }
}
