@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  src:
    url('../../assets/fonts/HelveticaNeue/NeueHelvetica55Roman/font.woff2') format('woff2'),
    url('../../assets/fonts/HelveticaNeue/NeueHelvetica55Roman/font.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: italic;
  font-weight: 500;
  src:
    url('../../assets/fonts/HelveticaNeue/NeueHelvetica56Italic/font.woff2') format('woff2'),
    url('../../assets/fonts/HelveticaNeue/NeueHelvetica56Italic/font.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 600;
  src:
    url('../../assets/fonts/HelveticaNeue/NeueHelvetica65Medium/font.woff2') format('woff2'),
    url('../../assets/fonts/HelveticaNeue/NeueHelvetica65Medium/font.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 700;
  src:
    url('../../assets/fonts/HelveticaNeue/NeueHelvetica75Bold/font.woff2') format('woff2'),
    url('../../assets/fonts/HelveticaNeue/NeueHelvetica75Bold/font.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 800;
  src:
    url('../../assets/fonts/HelveticaNeue/NeueHelvetica85Heavy/font.woff2') format('woff2'),
    url('../../assets/fonts/HelveticaNeue/NeueHelvetica85Heavy/font.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 900;
  src:
    url('../../assets/fonts/HelveticaNeue/NeueHelvetica95Black/font.woff2') format('woff2'),
    url('../../assets/fonts/HelveticaNeue/NeueHelvetica95Black/font.woff') format('woff');
}
