@import 'src/styles/base';

.wrapper {
  padding-bottom: $spacing-bottom-list;
}

.list.methods {
  @include custom-list;
}

.list-item.method {
  @include custom-list-item;
}

.payment-method-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px $gutter;
}

.no-data-wrapper {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    font-size: 16px;
  }

  @include respond-to-grid-4 {
    margin: 0 32px;
  }

  color: $black200;
  font-size: 14px;
  text-align: center;

  a {
    font-weight: bold;
    text-decoration: underline;
  }
}
