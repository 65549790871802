@import 'src/styles/base';

.wrapper {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    padding-left: 0;
    padding-right: 0;
  }

  padding: 0 32px;

  &.loading {
    padding-top: 20px;
  }
}

.wrapper-iframe-form {
  margin-top: $gutter;

  &.payix {
    margin-left: -16px;
    margin-right: -16px;
    border: $default-border-style;
    border-radius: 4px;
    overflow: hidden;

    @include respond-to-smaller-than-grid-4 {
      margin-left: -32px;
      margin-right: -32px;
    }
  }
}

.loan-note {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    font-size: 16px;
  }

  margin: 15px 0 10px;
  font-size: 14px;
  text-align: justify;

  p {
    margin: 0;
    padding: 0;

    + p {
      margin-top: 10px;
    }
  }

  span {
    font-weight: 700;
  }
}

$border: solid 1px $purple100;
$tab-hovered-background: #e9eef6;
$tab-active-color: $purple100;
$tab-height: 56px;

.list.tabs {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: $border;
  border-radius: 4px;
  margin-bottom: $gutter;
}

.list-item.tab {
  @include emphasized-text;

  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-right: $border;
  cursor: pointer;
  transition: 0.3s ease;
  height: $tab-height;
  color: $purple100;

  button {
    @include emphasized-text;

    cursor: pointer !important;
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    outline: 0 !important;
    width: 100% !important;
    height: $tab-height;
    color: $purple100;
    font-weight: 700;
    font-size: 16px;
    background: transparent !important;
  }

  &[data-tab-active='true'] {
    color: $white;
    background: $purple100;
    cursor: default;

    button {
      color: $white;
      cursor: pointer !important;
    }
  }

  &[data-disabled='true'] {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:hover[data-tab-active='false'][data-disabled='false'] {
    background: $tab-hovered-background;
    transition: 0.3s ease;
  }

  &:last-child {
    border-right: 0;
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }
}
