@import 'src/styles/base';

@mixin responsive-drawer(
  $img-size: 50px,
  $fullname-font-size: 18px,
  $main-list-item-font-size: 16px,
  $sub-list-item-font-size: 14px
) {
  .user-profile {
    padding: 44px 0 22px;
    position: relative;
    text-align: center;

    .icon-photo {
      height: $img-size;
      margin-bottom: 16px;
      width: $img-size;
    }

    .fullname {
      font-family: $font-baloo-bhai;
      font-weight: 700;
      font-size: $fullname-font-size;
      line-height: 1;
      text-transform: capitalize;
    }
  }

  .list {
    padding: 0;

    .list-item {
      font-weight: 400;
      padding: 0;

      a {
        padding: 12px 32px;
        width: 100%;
      }

      div {
        padding: 12px 32px;
        width: 100%;
      }
    }

    &.main {
      margin: 10px 0 24px;

      .list-item {
        font-size: $main-list-item-font-size;
        line-height: 20px;

        a {
          color: $text-default;
        }
      }
    }

    &.sub {
      .list-item {
        font-size: $sub-list-item-font-size;
        line-height: 18px;

        a {
          color: $text-default;
        }
      }
    }
  }
}

:global {
  .MuiDrawer-root {
    .MuiPaper-root.MuiDrawer-paper.paper-wrapper {
      @include respond-to-grid-4 {
        width: 288px;
      }

      @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
        max-width: 314px;
      }

      height: 100%;
      width: 50%;

      &:focus {
        outline: 0;
      }
    }
  }
}

.button {
  padding: 12px 32px;

  & button {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.wrapper {
  @include responsive-drawer;

  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    @include responsive-drawer(
      $img-size: 60px,
      $fullname-font-size: 20px,
      $main-list-item-font-size: 18px,
      $sub-list-item-font-size: 16px
    );
  }

  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  .icon-arrow-right {
    position: absolute;
    right: 32px;
    top: 50%;

    path {
      fill: $purple100;
    }
  }

  .lists {
    flex: 1;
  }

  .version {
    padding: 28px 0;
    text-align: center;
  }
}
