@import 'src/styles/base';

.late-fee-dialog-info {
  margin: 0;

  & + .late-fee-dialog-info {
    margin-top: 10px;
  }

  &:last-child {
    font-weight: 700;
  }
}
