@import 'src/styles/base';

:global {
  .MuiButton-label {
    padding-top: 2px; // fixes MUI misalignment
  }
}

.wrapper {
  @include margin-y(3px);

  &.btn-center {
    text-align: center;
  }

  &.btn-left {
    text-align: left;
  }

  &.btn-right {
    text-align: right;
  }

  &.btn-transparent button {
    background-color: $grey1200;
    color: $midnight50;
  }
}
