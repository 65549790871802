@import 'src/styles/base';

.wrapper {
  h1 {
    @include text($font-size: 18px, $line-height: 24px);

    margin: auto;
    max-width: 16ch;
    text-align: center;
  }

  h4 {
    @include text($font-size: 12px, $line-height: 24px);

    text-align: left;
    margin-left: 12px;
    margin-bottom: 6px;
  }

  .details {
    @include with-light-drop-shadow;
    @include text($font-size: 14px, $line-height: 24px);

    background-color: $white;
    color: $midnight100;
    padding: 28px 40px 16px;
    text-align: left;
    width: $col-width-sm;

    @include respond-to-grid-4 {
      @include padding-x(24);
    }
  }

  .disclaimer {
    @include text($font-size: 14px, $line-height: 24px);

    color: $midnight100;
    padding: 28px 40px 16px;
    text-align: left;
    width: $col-width-sm;

    @include respond-to-grid-4 {
      @include padding-x(24);
    }
  }
}
