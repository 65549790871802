@import 'src/styles/base';

.selected-payment-method {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: -6px 0;

  &.disabled {
    opacity: 0.38;
  }

  .spinner {
    margin-right: 12px;
  }
}

.menu-item {
  &.payment-method-item {
    opacity: 0.5;
    padding: 0 30px;

    &.selected {
      opacity: 1;
    }
  }

  &.default-item {
    color: $black500;
    font-size: 14px;
    line-height: 18px;
    max-height: 48px;
    padding: 0 24px;

    &:hover {
      background: $grey400;
    }

    &.selected {
      background: transparent;
      font-weight: 700;
      position: relative;

      &::before {
        background: url('~assets/icons/icon-green-check.svg') no-repeat center;
        content: '';
        height: 9px;
        left: 8px;
        position: absolute;
        width: 12px;
      }
    }
  }
}

.custom.loading {
  display: block !important;
  margin: 10px auto;
}

.extra-item-wrapper {
  align-items: center;
  background: $grey400;
  border-top: 0.5px solid $grey1000;
  color: $primary;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-weight: 700;
  justify-content: center;
  line-height: 40px;
  margin-bottom: -8px;
  margin-top: 8px;

  svg {
    margin-right: 6px;
  }

  &:hover {
    background: $grey400;
  }
}
