@import 'src/styles/base';

.tag-refund-wrapper {
  display: flex;
  justify-content: center;

  .tag-refund {
    background: $grey300;
    color: $white;
    line-height: 20px;
    padding: 0 6px;
    font-size: 10px;
    border-radius: 3px;
    margin-bottom: 15px;

    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      line-height: 24px;
      padding: 0 8px;
      font-size: 12px;
    }
  }
}

.total-payment-amount {
  padding: 0 0 20px;
  text-align: center;

  &.on-screen {
    margin-bottom: 24px;
    padding: initial;
  }

  .total-payment-amount-label {
    color: $grey700;
    font-size: 12px;
    line-height: 15px;

    &.on-screen {
      color: initial;
      font-size: initial;
    }
  }

  .total-payment-amount-value {
    @include emphasized-text;

    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      font-size: 32px;
    }

    font-size: 30px;
    margin-top: 5px;

    &.on-screen {
      font-family: $font-baloo-bhai !important;
      color: $primary;
      font-size: 36px;
      font-weight: 700;
      line-height: 45px;
    }
  }
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 22px;

  &.on-screen {
    align-items: center;
    flex-direction: initial;
  }

  .label {
    color: $grey700;
    font-size: 12px;
  }

  .value {
    align-items: center;
    color: $black200;
    font-size: 14px;

    img.on-screen {
      margin-right: 8px;
      width: 38px;
    }

    svg {
      margin-right: 10px;

      &.on-screen {
        margin-right: 8px;
      }
    }

    & + .item {
      margin-top: 8px;
    }

    &.emphasized {
      .label {
        // color: $text-default;
        font-weight: 700;
      }

      .value {
        color: $primary;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    & + .item {
      margin-top: 16px;
    }

    .label {
      font-size: 14px;
    }

    .value {
      font-size: 16px;
    }
  }
}

.blocks {
  .block {
    border-top: 1px solid $blue100;
    padding: 16px 0;

    &:last-child {
      padding-bottom: 0;
    }
  }

  /* stylelint-disable */
  /* fixing no-descending-specificity not possible due to nesting */
  &.ach,
  &.pad,
  &.card .block:nth-child(2) {
    .item {
      &:nth-child(1) {
        .value {
          @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
            max-width: 300px;
          }

          max-width: 200px;
        }
      }

      &:nth-child(2) {
        .value {
          max-width: 180px;
          word-break: break-all;
        }
      }

      &:nth-child(3) {
        .value {
          @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
            max-width: 200px;
          }

          max-width: 115px;
        }
      }

      &:nth-child(4) {
        .value {
          @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
            max-width: 290px;
          }

          max-width: 190px;
        }
      }

      &:nth-child(6) {
        .value {
          @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
            max-width: 320px;
          }

          max-width: 225px;
        }
      }

      &:nth-child(7) {
        .value {
          @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
            max-width: 320px;
          }

          max-width: 175px;
        }
      }

      &:nth-last-child(2) {
        .value {
          display: flex;
          flex-direction: row;
        }
      }

      &:last-child {
        .value {
          @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
            max-width: 310px;
          }

          max-width: 210px;
        }
      }

      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(6),
      &:nth-child(7),
      &:last-child {
        .value {
          @include truncated;
        }
      }
    }
  }
  /* stylelint-enable */
}
