@import 'src/styles/base';

.wrapper {
  .col {
    @include margin-x;

    width: $col-width;

    .heading,
    .subheading {
      text-align: center;
    }

    .heading {
      @include text($font-size: 20px);

      margin-top: 32px;
    }

    .subheading {
      @include subtle-text($font-size: 13px);

      margin-top: -4px;
      margin-bottom: 28px;
    }
  }
}
