@import 'base';

:global {
  #root {
    height: 100%;
  }

  .fixed-bottom {
    @include respond-to-grid-4 {
      left: 32px;
      right: 32px;
    }

    @include respond-to-grid-8 {
      left: 50%;
      max-width: 352px;
      transform: translate(-50%, 0);
    }

    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      bottom: calc(#{$bottom-fab-height} + #{$fab-height-large} + 16px);
      left: 50%;
      max-width: 640px;
      transform: translate(-50%, 0);
    }

    bottom: calc(#{$bottom-fab-height} + #{$fab-height-small} + 16px);
    left: 0;
    right: 0;
    position: fixed;
    text-align: center;
    z-index: -1;
  }

  .extra-item-wrapper {
    align-items: center;
    background: $grey400;
    border-top: 0.5px solid $grey1000;
    color: $primary;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-weight: 700;
    justify-content: center;
    line-height: 40px;
    margin-bottom: -8px;
    margin-top: 8px;

    svg {
      margin-right: 6px;
    }

    &:hover {
      background: $grey400;
    }
  }

  .contains-maven-widget .osano-cm-widget--position_right {
    right: 150px !important;
    bottom: 18px !important;
  }

  #maven-chat-widget > div {
    margin-bottom: 0.15rem !important;
  }
}

* {
  font-family: $font-helvetica-neue !important;
}

a {
  color: $primary;
  text-decoration: none;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-size: 14px;
  color: $text-default;
  margin: 0;
  box-sizing: border-box;
  background-color: $bg-main;
}

input {
  &:focus {
    outline: 0;
  }
}
