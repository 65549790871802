@import 'src/styles/base';

:global {
  :local(.wrapper) {
    background: $white;

    .MuiFormLabel-root {
      color: $text-default;

      &.Mui-focused {
        color: $secondary;
      }
    }

    &.MuiTextField-root {
      margin-top: 0;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $grey600;
    }

    .MuiSelect-icon {
      @include respond-to-grid-4 {
        right: 6px;
      }

      right: 16px;

      path {
        &:nth-child(2) {
          fill: $purple100;
        }
      }
    }

    .MuiSelect-selectMenu {
      min-height: unset;
    }
  }
}

.wrapper {
  width: 100%;

  &.disabled {
    svg {
      opacity: 0.4;
    }
  }
}
