@import 'src/styles/base';

.wrapper {
  @include respond-to-grid-4 {
    @include padding-x(5%);
  }

  padding: 100px 30px 70px;
  text-align: center;
  font-size: 12px;

  .help {
    padding-bottom: 16px;
  }

  .links {
    @include padding-x(10px);
    @include margin-x(auto);

    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 400px;

    .link {
      width: max-content;
    }
  }

  .divider {
    $margin: 4px;

    @include respond-to-grid-4 {
      $margin: 16px;
    }

    @include margin-x($margin);

    background: $black-opacity-light;
  }

  .webbank-language {
    margin: 100px auto 0;
    color: $grey1400;
    text-align: center;
    max-width: 400px;
  }
}
