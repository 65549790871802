@import 'src/styles/base';

.wrapper {
  .details {
    @include text($font-size: 14px, $line-height: 24px);

    background-color: $white;
    color: $midnight100;
    padding: 0 40px 40px;
    text-align: left;
    width: $col-width-sm;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @include respond-to-grid-4 {
      @include padding-x(24);
    }
  }

  .generic-text {
    line-height: normal;
  }

  .terms-text {
    @include text($font-size: 12px, $line-height: 20px);
  }

  .main-box {
    @include text($font-size: 12px, $line-height: 20px);

    display: grid;
    width: 100%;
    grid-template-columns: 90% 10%;
  }
}
