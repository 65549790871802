@import 'src/styles/base';

.wrapper {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    padding: 0;
  }

  padding: 0 32px;
  background: $primary;
  height: 100vh;
}

.wrapper-logo {
  @include respond-to-smaller-than-grid-4 {
    padding-bottom: $gutter;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 30px;

  .icon.heart {
    width: 55px;
    height: 55px;
    margin-bottom: 16px;

    @include respond-to-smaller-than-grid-4 {
      width: 40px;
      height: 40px;
    }
  }

  .logo {
    @include respond-to-grid-4 {
      max-width: 190px;
    }

    @include respond-to-smaller-than-grid-4 {
      max-width: 150px;
    }

    height: auto;
    max-width: 212px;
    width: 100%;
  }
}

.disclaimer {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    font-size: 14px;
    line-height: 16px;
  }

  color: $white;
  opacity: 0.8;
  font-size: 12px;
  line-height: 14px;
  margin: 8px 0 $gutter;
  text-align: left;
}

.wrapper-btn-resend {
  display: flex;
  justify-content: center;
}

.btn {
  &.resend {
    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      font-size: 14px;
    }

    @include simple-button;

    color: $secondary;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    text-decoration: underline;

    &:disabled {
      color: $grey600;
      text-decoration: none;
      pointer-events: none !important;
      background-color: $grey800;
      border-radius: 20px;
      margin-bottom: 10px;
      padding: 5px 20px;
    }
  }
}

.error-message {
  color: $red100;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.0333em;
  line-height: 1em;
  margin: 8px 12px 0;

  a {
    color: $red100;
    font-weight: 700;

    &:hover {
      color: $red100;
      text-decoration: underline;
    }
  }
}
