@import 'src/styles/base';

.wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 24px 32px;
}

.tile {
  @include with-light-drop-shadow;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: calc(50% - 24px);
  margin: 8px 0;
  padding: 8px;
  border-radius: 8px;
  line-height: 1;

  .title {
    color: $purple200;
    font-size: 12px;
    white-space: pre-wrap;
    font-weight: 700;
    padding-top: 8px;
  }

  .sub-title {
    color: $purple200;
    font-size: 10px;
    white-space: pre-wrap;
    font-weight: 300;
    margin-top: 3px;
  }

  .value {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: $primary;
    font-size: 18px;
    padding: 15px;
  }
}

.custom.loading {
  margin-left: 5px;

  svg {
    color: $primary;
  }
}
