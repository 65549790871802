@import 'src/styles/base';

.wrapper {
  width: 100%;

  @include respond-to-grid-xlarger {
    margin-left: auto;
    margin-right: auto;
    max-width: $screen-xlg;
  }
}

.wrapper-item {
  &.col {
    width: 100%;
  }
}
