@font-face {
  font-family: Mulish;
  font-style: normal;
  font-weight: 200;
  src: url('../../assets/fonts/Mulish/Mulish-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: Mulish;
  font-style: italic;
  font-weight: 200;
  src: url('../../assets/fonts/Mulish/Mulish-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: Mulish;
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/Mulish/Mulish-Light.ttf') format('truetype');
}

@font-face {
  font-family: Mulish;
  font-style: italic;
  font-weight: 300;
  src: url('../../assets/fonts/Mulish/Mulish-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: Mulish;
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/Mulish/Mulish-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Mulish;
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/Mulish/Mulish-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Mulish;
  font-style: italic;
  font-weight: 700;
  src: url('../../assets/fonts/Mulish/Mulish-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: Mulish;
  font-style: normal;
  font-weight: 900;
  src: url('../../assets/fonts/Mulish/Mulish-Black.ttf') format('truetype');
}

@font-face {
  font-family: Mulish;
  font-style: italic;
  font-weight: 900;
  src: url('../../assets/fonts/Mulish/Mulish-BlackItalic.ttf') format('truetype');
}
