@import 'src/styles/base';

.wrapper {
  width: $col-width;
  padding-bottom: $spacing-bottom-list;

  .payment-method {
    @include with-light-drop-shadow;

    padding: 0;

    div {
      border-radius: 15px;
    }
  }

  .card-title {
    @include card-title;
  }

  .add-payment {
    padding-top: 50px;
    padding-bottom: 200px;
    padding-left: 30px;
    color: inherit;

    a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 20px;
      color: inherit;

      svg {
        margin-top: -3px;
      }
    }

    &.add-payment-disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
