@import 'src/styles/base';

$stripe-card-border: rgba(0 0 0 / 0.6);

.payment-note {
  font-size: 12px;
  margin: 15px auto;
  max-width: 100%;
  text-align: center;
}

:global {
  .StripeElement {
    border: $default-border-style;
    background: $white;
    border-radius: 4px;
    margin-top: $gutter;
    padding: 19px 14px;

    &--focus {
      border-color: $secondary;
      border-width: 2px;
      padding: 17px 12px;
    }

    &--invalid {
      border-color: $red100;
    }
  }
}

.error-message {
  color: $red100;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.0333em;
  line-height: 1em;
  margin: 8px 12px 0;

  a {
    color: $red100;
    font-weight: 700;

    &:hover {
      color: $red100;
      text-decoration: underline;
    }
  }
}

@media (max-height: 600px) {
  .add-card-button {
    display: none;
  }
}
