@import 'src/styles/base';

:global {
  :local(.field-wrapper) {
    .MuiButtonBase-root {
      // allows for better alignment with text
      padding-top: 0;
      margin-top: -2px;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;

  @include padding-x(3%);

  > div {
    padding-right: 8px;
  }

  .field-wrapper {
    @include respond-to-smaller-than-grid-4 {
      padding-right: 10px;
    }

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px;

    .input-group {
      @include respond-to-smaller-than-grid-4 {
        flex-direction: column;
        letter-spacing: 0;
      }

      position: relative;
      display: flex;
      align-items: flex-start;

      .label-group {
        align-items: flex-start;
        text-align: left;
        padding-left: 8px;
        margin-right: 10px;

        span {
          font: inherit;
        }
      }

      .label-info {
        button {
          @include respond-to-smaller-than-grid-4 {
            margin-left: 37px;
            margin-top: 0;
          }

          font-size: 8px;
          padding: 1px 5px;
          border-radius: 4px;
          margin-top: -8px;
          cursor: initial;
        }
      }
    }

    .due-date {
      font-size: 12px;
    }

    .amount {
      $spacing-y: 12px;
      $spacing-right: 8px;

      @include padding-y($spacing-y);

      padding-right: $spacing-right;
      margin-top: -$spacing-y;
      margin-left: auto;
      margin-right: -$spacing-right;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 16px;
      text-align: right;

      &.amount-input {
        color: $midnight50;
        background: $grey1200;
        border: 1px solid $grey1200;
        border-radius: 7px;
        padding-left: 12px;
        visibility: visible;
      }

      &.amount-hide {
        @extend .amount-input;

        visibility: hidden;
      }

      div {
        font: inherit;
        color: inherit;
        min-width: 75px;
      }

      input {
        @include respond-to-smaller-than-grid-4 {
          width: 100%;
        }

        all: unset;
        display: inline-block;
        width: 75px;
        text-align: right;
        appearance: none;
        border: none;
        outline: none;
        background: inherit;
        font: inherit;
      }
    }

    .spacer {
      display: block;
      margin-bottom: 48px;
    }
  }

  & > div .field-wrapper {
    padding-bottom: 6px;
  }

  & > div:not(:first-of-type) .field-wrapper {
    padding-top: 20px;
  }

  & > div:nth-of-type(2) .field-wrapper {
    @include respond-to-smaller-than-grid-4 {
      overflow: hidden;
      flex-flow: column wrap;
      gap: 8px;
      max-width: 100%;
    }
  }

  & > div:last-of-type .field-wrapper {
    padding-top: 20px;
    padding-bottom: 0;
  }

  .error-input {
    @include padding-y(8px);

    color: $error;
    text-align: right;
    max-width: 20ch;
    margin-left: auto;
    margin-right: 0;
  }

  .sub-wrapper {
    display: none;

    &.sub-wrapper-show {
      display: block;
      padding-top: 8px;
    }

    .sub-field-wrapper {
      @include subtle-text;

      @include respond-to-grid-4 {
        padding-left: 16px;
      }

      @include respond-to-smaller-than-grid-4 {
        padding-right: 8px;
      }

      height: 12px;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-left: 48px;
      padding-bottom: 4px;
      vertical-align: text-top;

      &:last-of-type {
        padding-bottom: 16px;
      }

      .sub-field-title {
        display: flex;
        align-items: flex-start;
      }

      .sub-field-icon {
        margin-top: -2px;
        margin-left: 2px;
        font-size: 14px;
        cursor: pointer;

        a {
          color: inherit;
        }
      }
    }
  }

  .wip {
    @include respond-to-smaller-than-grid-4 {
      padding-top: 12px;
    }

    width: 25ch;
    padding-left: 36px;
  }
}
