@import 'src/styles/base';

.wrapper {
  @include respond-to-grid-4 {
    padding-top: 8px;
  }

  padding: 16px 0 100px;
  text-align: center;
}

.explanation {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    font-size: 14px;
  }

  @include respond-to-grid-4 {
    margin-left: $gutter;
    margin-right: $gutter;
  }

  margin: 0 32px 32px;
  color: $primary;
  font-size: 12px;
  text-align: center;
}

.icon.heart {
  width: 55px;
  height: 55px;
  margin-bottom: 32px;
}

.btn-find-plan {
  @include padding-x(24px);

  button {
    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      font-size: 16px;
    }

    font-size: 14px;
    line-height: 36px;
  }
}
