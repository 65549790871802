@use 'sass:color';
@import 'src/styles/base';

.navigation-link {
  a {
    color: $midnight50;
    text-decoration: underline;

    @include interactive-states {
      color: color.adjust($color: $midnight50, $lightness: -40%);
    }
  }
}
