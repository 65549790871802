@import 'src/styles/base';

.icon {
  &.icon-large {
    max-height: 19px;
    max-width: 42px;
  }

  &.icon-medium {
    max-height: 14px;
    max-width: 42px;
  }

  &.icon-small {
    height: 12px;
    max-height: 10px;
    width: auto;
  }

  &.jcb {
    max-height: none;
  }
}
