@import 'src/styles/base';

.wrapper {
  background: $white100;
  text-align: center;
  margin-top: 64px;
}

.content-container {
  width: 350px;
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 90px;
  text-align: center;

  .heading {
    color: #293d99;
  }

  .heading-404 {
    margin-top: -70px;
    font-size: 25px;
    margin-bottom: -40px;
  }

  .content {
    line-height: 1.5em;

    .cta-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .cta {
        margin-top: 20px;
        border-radius: 7px;
        font-size: 14px;
        line-height: 19px;
        font-weight: 500;
        letter-spacing: 0.88px;
        border: none;
        cursor: pointer;
        color: #fff;
        background-color: #293d99;
        text-transform: uppercase;
        position: relative;
        height: 40px;
        width: 124px;
        min-width: 124px;
        display: table-cell;
        vertical-align: middle;
        padding: 0;
        text-align: center;
        text-decoration: none;

        &.small {
          width: 172px;
          height: 47px;
          line-height: 47px;
        }
      }
    }
  }
}
