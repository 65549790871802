@import 'src/styles/base';

.wrapper {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    margin: 3px 0;
    font-size: 14px;
  }

  font-size: 12px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.account-holder {
  align-items: center;
  display: flex;
  flex-direction: row;

  .holder-name {
    @include emphasized-text;

    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      font-size: 16px;
    }

    font-size: 14px;
    margin-left: 5px;
    margin-top: 2px;
  }
}

.account-number {
  @include respond-to-smaller-than-grid-4 {
    gap: 12px;
  }

  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding-left: 10px;

  .digits {
    align-items: center;
    display: flex;
    flex-direction: row;

    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      font-size: 14px;
    }

    color: $text-default;
    font-size: 12px;

    .hidden-digits {
      @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
        font-size: 14px;
      }

      font-size: 14px;
      margin-left: 5px;
      margin-top: 3px;
    }
  }
}
