@import 'src/styles/base';

.wrapper {
  &.back-header {
    background: $white;
    box-shadow: none;
    margin-top: 20px;
    position: relative;
    text-align: center;
  }
}

.btn {
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  svg {
    width: 10px;
    height: 10px;
  }

  &.icon-back,
  &.icon-close {
    &.light {
      color: $midnight100;

      svg {
        path {
          fill: $midnight100;
        }
      }
    }

    &.dark {
      color: $white;

      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  &.icon-back {
    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      left: 10px;
    }

    left: 4px;
  }

  &.icon-close {
    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      right: 12px;
    }

    right: 6px;
  }

  .icon-text {
    padding-left: 16px;
    font-size: 12px;
    margin-top: 2px;
  }
}
