@import 'src/styles/base';

$spinner-size: 30px;

.loading {
  &.default-size {
    display: block;
    height: $spinner-size !important;
    margin: 0 auto 8px;
    width: $spinner-size !important;

    svg {
      height: $spinner-size;
      width: $spinner-size;
    }
  }

  &.default-color {
    svg {
      color: $purple100;
    }
  }
}
