@import 'src/styles/base';

.wrapper {
  padding-bottom: $spacing-bottom-list;
}

.list.loans {
  @include custom-list;
}

.list-item.loan {
  @include custom-list-item;
}

.loan-number {
  font-size: 16px;
  font-weight: 700;
}

.wrapper-add-loan-btn {
  margin: 6px auto 0;
  text-align: center;
  width: 100%;

  .link-to-loan-details {
    display: inline-block;
    padding: 0 15px;

    * {
      display: inline-block;
      vertical-align: middle;
    }

    span {
      font-size: 14px;
      font-weight: 700;
      line-height: 40px;
      margin-left: 6px;
    }
  }
}

.custom.loading {
  &.apply-loan {
    margin: 0;

    svg {
      color: $white;
    }
  }
}
