@import 'src/styles/base';

.icon {
  &.card,
  &.bank {
    width: 11px;

    path {
      fill: $text-default;
    }
  }

  &.card {
    height: 18px;
  }
}
