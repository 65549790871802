@import 'src/styles/base';

:global {
  :local(.wrapper-tabs) {
    padding: 0 $gutter;

    .MuiTab-root {
      min-width: 0;
      width: 33.3%;
    }

    .MuiButtonBase-root {
      @include text;

      @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
        font-size: 14px;
      }

      font-size: 12px;
      text-transform: none;
      padding: 0;
      height: 52px;
      opacity: 1;
    }

    .MuiTabs-indicator {
      background: transparent;
    }
  }

  :local(.tab-indicator) {
    position: absolute;
    bottom: 11px;
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    width: 24px;
    border-radius: 1px;
    background: $midnight50;
  }
}

.wrapper-grey-bg {
  width: 100%;
  background: $white100;
  margin-bottom: 10px;

  .col > div {
    width: $col-width;
  }
}

.wrapper-tab-panels {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1;
  background: $white100;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    z-index: 0;
    opacity: 0.4;
  }
}
