@mixin truncated {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin simple-button {
  padding: 0;
  text-transform: none;

  &:hover {
    background: transparent;
    text-decoration: underline;
  }
}

$default-border-style: solid 0.5px $grey600;

@mixin custom-list-item-has-left-and-right-border-on-large-screen {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    border-left: $default-border-style;
    border-right: $default-border-style;
  }

  @include respond-to-grid-8 {
    border-left: $default-border-style;
    border-right: $default-border-style;
  }
}

@mixin custom-list-item-has-no-left-and-right-border-on-large-screen {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    border-left: 0;
    border-right: 0;
  }

  @include respond-to-grid-8 {
    border-left: 0;
    border-right: 0;
  }
}

@mixin custom-list-item {
  @include custom-list-item-has-left-and-right-border-on-large-screen;

  border: $default-border-style;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  height: 54px;
  width: 100%;
  color: $text-default;
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:last-child {
    border-bottom: $default-border-style;
  }
}

@mixin custom-list {
  padding: 0 !important;
  width: 100%;
}

@mixin has-border-bottom-radius {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

@mixin has-border-top-radius {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

@mixin emphasized-text {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    font-size: 18px;
  }

  font-size: 16px;
  font-weight: 700;
  color: $primary;
  line-height: 1;
}

@mixin subtle-text($font-size: 12px, $line-height: 12px) {
  font-size: $font-size;
  color: $grey1400;
  letter-spacing: 0;
  line-height: $line-height;
  font-weight: 400;
}

@mixin text($font-size: 12px, $line-height: 12px, $font-weight: 400) {
  font-size: $font-size;
  color: #1d1d30;
  letter-spacing: 0;
  line-height: $line-height;
  font-weight: $font-weight;
}

@mixin margin-x($size: auto) {
  margin-left: $size;
  margin-right: $size;
}

@mixin margin-y($size: auto) {
  margin-top: $size;
  margin-bottom: $size;
}

@mixin padding-x($size) {
  padding-left: $size;
  padding-right: $size;
}

@mixin padding-y($size) {
  padding-top: $size;
  padding-bottom: $size;
}

@mixin with-light-drop-shadow {
  font-size: 12px;
  background: #fff;
  box-shadow: 0 3px 14px 3px #e8e8ff;
  border-radius: 15px;
}

@mixin interactive-states {
  &:hover,
  &:focus-visible,
  &:active {
    @content;
  }
}

@mixin card-title {
  text-align: left;
  align-self: flex-start;
  font-size: 12px;
  margin-left: 16px;

  &:not(:first-of-type) {
    padding-top: $spacing-bottom-text-xl;
  }
}
