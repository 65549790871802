@import 'src/styles/base';

.modal-wrapper {
  color: $midnight100;
  background: $white;
  width: $col-width-sm;
  padding: 36px 48px;
  margin: 25vh auto auto;
  border-radius: 15px;
  box-sizing: border-box;

  h1 {
    @include text($font-size: 20px, $font-weight: 700);

    margin-bottom: 24px;
  }

  p {
    margin-bottom: 24px;
    line-height: 24px;
  }

  .action,
  a {
    font-weight: 700;
    color: $midnight50;
  }

  .action {
    display: block;
    margin-bottom: 12px;
  }
}
