@import 'src/styles/base';

.wrapper {
  margin-top: 20px;
  position: relative;

  .text-field {
    width: 100%;
  }

  &.white-style {
    margin-top: 0;

    .text-field {
      background: transparent;
    }
  }
}

:global {
  :local(.contain-prefix) {
    .MuiOutlinedInput-root {
      padding-left: 14px;
    }
  }

  :local(.wrapper) {
    .MuiOutlinedInput-notchedOutline {
      border-color: $grey600;
    }

    &:not(:local(.disabled-style)) {
      .MuiInputBase-input {
        color: $text-default;
      }
    }

    &:not(:local(.white-style)) {
      .MuiInputBase-root {
        background: $white;
      }
    }
  }

  :local(.wrapper.white-style) {
    .MuiOutlinedInput-notchedOutline {
      border-color: $white;
      background: rgba(255 255 255 / 0.1);
    }

    .MuiFormLabel-root {
      color: $white;

      &.Mui-focused {
        color: $secondary;
      }
    }

    .MuiOutlinedInput-root {
      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: $secondary;
        }
      }

      &:hover:not(.Mui-focused) {
        .MuiOutlinedInput-notchedOutline {
          border-color: $white;
        }
      }
    }

    .MuiInputBase-input {
      color: $white;

      &::placeholder {
        color: $white;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: none !important;
        -webkit-text-fill-color: $white;
        box-shadow: 0 0 0 60px #001684 inset;

        // For Safari to remove "yellow" background of autofill input
        transition: background-color 5000s ease-in-out 0s;

        // Chrome iOS
        background-clip: content-box !important;
      }
    }
  }
}

.custom.adornment {
  p {
    color: $text-default;
    font-size: 18px;
    font-weight: 700;
  }
}
