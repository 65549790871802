@import 'src/styles/base';

.link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .text {
    color: $midnight100;
  }

  .icon {
    width: 30px;
    height: 30px;
  }
}
