@import 'src/styles/base';

.wrapper {
  background: $white;
  text-align: center;
  padding-top: 60px;

  .col > div {
    width: $col-width-sm;
  }

  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    padding-left: 0;
    padding-right: 0;
  }
}
