@import 'src/styles/base';

.wrapper {
  width: 100%;
  text-align: center;

  a {
    color: $midnight100;
    font-size: 14px;
  }
}
