@font-face {
  font-family: 'Baloo Bhai 2';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/BalooBhai2/BalooBhai2-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Baloo Bhai 2';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/BalooBhai2/BalooBhai2-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Baloo Bhai 2';
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/BalooBhai2/BalooBhai2-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Baloo Bhai 2';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/BalooBhai2/BalooBhai2-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Baloo Bhai 2';
  font-style: normal;
  font-weight: 800;
  src: url('../../assets/fonts/BalooBhai2/BalooBhai2-ExtraBold.ttf') format('truetype');
}
