@import 'src/styles/base';

.block {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    font-size: 12px;
    margin-top: 3px;
  }

  color: $primary;
  align-items: center;
  display: flex;
  font-size: 12px;
  justify-content: space-between;

  & + .block {
    margin-top: 3px;
  }
}

.call-practice-border {
  color: $purple200;
  font-size: 12px;
  font-weight: 900;
  padding-right: 15px;
  padding-left: 5px;
  margin: auto;
  width: max-content;
  height: 35px;
  border: 1px solid $purple200;
  border-radius: 5px;
}

.call-practice {
  flex: 1;
  text-align: left;
  color: $purple200;
}
