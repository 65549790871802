@import 'src/styles/base';

.wrapper {
  @include respond-to-grid-4 {
    padding: $gutter 16px;
  }

  padding: $gutter 32px;
}

.title {
  @include emphasized-text;

  line-height: 20px;
}

:global {
  @media only screen and (max-height: 480px) {
    .MuiDialog-paper.MuiDialog-paperFullScreen {
      margin: 0 16px;
      max-width: 100%;

      :local(.wrapper-content) {
        max-height: calc(100vh - 200px);
      }
    }
  }
}

.wrapper-content {
  @include respond-to-grid-4 {
    margin: 20px 0 36px;
  }

  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    font-size: 16px;
  }

  color: $black300;
  font-size: 14px;
  margin: 24px 0 36px;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  text-align: justify;
}

.wrapper-actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.btn {
  &.action {
    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      font-size: 16px;
    }

    background: transparent;
    color: $purple100;
    font-size: 14px;
    font-weight: 700;
    padding: 0;
    text-transform: uppercase;

    &.negative {
      color: $grey800;
    }

    &:hover {
      background: transparent;
    }

    & + .btn {
      @include respond-to-grid-4 {
        margin-left: 16px;
      }

      margin-left: 32px;
    }
  }
}
