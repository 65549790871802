@import 'src/styles/base';

.wrapper {
  padding-bottom: $spacing-bottom-list;
}

.list.payments {
  @include custom-list;
}

.date {
  font-size: 16px;
  font-weight: bold;
}

.list-item.payment {
  @include custom-list-item;

  &.has-refund {
    .date,
    .amount-wrapper *:not(.tag-refund) {
      opacity: 0.4;
    }
  }
}

.amount-wrapper {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
}

.amount {
  font-size: 16px;
  margin-left: 10px;
}

.tag-refund {
  background: $grey300;
  color: $white;
  line-height: 20px;
  padding: 0 6px;
  font-size: 10px;
  border-radius: 3px;
  margin-right: 10px;

  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    line-height: 24px;
    padding: 0 8px;
    font-size: 12px;
  }

  & + .amount {
    margin-left: 0;
  }
}

.no-data-wrapper {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    font-size: 16px;
  }

  @include respond-to-grid-4 {
    margin: 0 32px;
  }

  color: $black200;
  font-size: 14px;
  text-align: center;

  a {
    font-weight: bold;
    text-decoration: underline;
  }
}
