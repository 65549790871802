@import 'src/styles/base';

.wrapper {
  .appbar-wrapper {
    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      height: $header-height-large;
    }

    height: $header-height-small;
    margin: 0;

    &.elevation-appbar {
      background: $bg-main;
      text-align: center;
      display: flex;
      place-items: center;

      &.darkblue-appbar {
        background: $primary;
      }

      &.light-appbar {
        background: $white100;
      }

      &.unsticky-appbar {
        position: relative;
        left: auto;
        right: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        .inner-nav-unsticky {
          width: 400px;
          height: 100%;
          position: relative;
          left: 4px;
          margin: auto;
        }
      }
    }
  }

  .gap {
    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      height: $header-height-large;
    }

    height: $header-height-small;
    width: 100%;
    margin: 0;
  }

  .inner-nav-sticky {
    @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
      margin-right: 480px;
    }

    @include respond-to-grid-8 {
      margin-right: 250px;
    }

    @include respond-to-grid-4 {
      margin-right: 500px;
    }

    width: 100%;
    height: 100%;
    margin-left: auto;
  }

  .inner-nav {
    @include margin-x(auto);

    position: relative;
    max-width: $screen-lg;
  }
}
