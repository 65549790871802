@import 'src/styles/base';

:global {
  .MuiButton-label {
    padding-top: 2px; // fixes MUI misalignment
  }
}

.wrapper {
  @include margin-y(3px);

  button {
    @include padding-x(12px);

    text-transform: none;
    font-weight: 700;
    border-radius: 10px;
    white-space: nowrap;
  }

  &.btn-standard button {
    @include interactive-states {
      background-color: $midnight50;
      color: $grey1200;
    }

    background-color: $midnight50;
    color: $grey1200;
  }

  &.btn-info button {
    @include interactive-states {
      background-color: $white;
      color: $midnight50;
    }

    background-color: $white;
    color: $midnight50;
    outline: 2px solid $midnight50;
  }

  &.btn-alert button {
    @include interactive-states {
      background-color: $white;
      color: $red100;
    }

    background-color: $white;
    color: $red100;
    outline: 2px solid $red100;
  }

  &.btn-disabled button,
  button:disabled {
    background-color: $grey1200;
    color: $midnight50;
    outline: 2px solid $grey1200;
  }
}
