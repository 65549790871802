@import 'src/styles/base';

.col > div {
  width: $col-width !important;
}

.wrapper {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    padding: 0 16px;
  }

  @include with-light-drop-shadow;

  text-align: center;

  &.congratulation {
    @include padding-y($spacing);

    .icon-congratulation {
      height: 36px;
      width: 36px;
    }
  }

  &.list-scheduled-payments {
    @include padding-y($spacing);
  }
}

.message {
  @include respond-to-grid($is-grid-12: true, $is-grid-lg: true, $is-grid-xlg: true) {
    font-size: 14px;
  }

  color: $primary;
  font-size: 12px;
  line-height: 18px;

  &.bold-text {
    font-weight: 700;
  }
}

.heading {
  color: $primary;
  font-size: 20px;
  font-weight: bold;
  margin: 8px 0;
}

.payment-dues-wrapper {
  text-align: center;

  $smaller-font-size: 11px;

  button {
    width: 100px;

    @include respond-to-smaller-than-grid-4 {
      width: 80px;
    }
  }

  .payment-dues-amount,
  .payment-dues-date {
    & > :first-child {
      padding-bottom: $spacing-bottom-text-sm;
    }
  }

  button,
  .payment-dues-amount,
  .payment-dues-date {
    @include respond-to-smaller-than-grid-4 {
      @include padding-x(0);

      font-size: 10px;
    }
  }

  .payment-dues-list-item {
    $padding: calc(min(16px, 5%));

    @include padding-x($padding);

    border-bottom: 1px solid $black-opacity-light;

    & > div {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      font-size: 12px;

      :not(:first-child) {
        align-self: center;
      }
    }
  }

  .payment-dues-amount {
    width: 70px;
    white-space: nowrap;
    font-size: 13px;

    @include respond-to-grid-4 {
      font-size: 12px;
      white-space: normal;
    }
  }

  .payment-dues-list-item:last-child {
    border-bottom: none;
  }

  .payment-dues-amount > :last-child {
    @include subtle-text($font-size: $smaller-font-size);
  }

  .payment-dues-date {
    width: 55px;
  }

  .payment-dues-date > :last-child {
    font-weight: 700;
  }

  .list.payment-dues {
    @include padding-y(0);
  }
}
